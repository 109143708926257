import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledLink = styled(Link)`
  position: relative;
  display: inline-flex;
  color: ${(props) => props.theme.color.secondary.main};
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 2px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-image-source: linear-gradient(to right, #11998e, #38ef7d);
  border-image-slice: 0 0 1 0;

  &:hover {
    border-image-source: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-image-source: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 0 0 1 0;
    margin: 0 auto;
    width: 0;

    transition: ${(props) =>
      props.theme.transition.create("width", {
        duration: props.theme.transition.duration.complex,
        easing: props.theme.transition.easing.easeOut,
        delay: 50,
      })};
  }

  &:hover:after {
    width: 100%;
  }
`;

interface Props {
  to: string;
}

const CategoryLink: React.FC<Props> = ({ to, children }) => {
  return <StyledLink to={to}>{children}</StyledLink>;
};

export default CategoryLink;
