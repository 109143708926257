export default (node) => {
  const {
    html,
    excerpt,
    fields: { slug, path },
    frontmatter: { id, title, author, category, description, posted_at },
  } = node;

  return {
    id,
    title,
    author,
    category,
    description,
    posted_at,
    html,
    excerpt,
    slug,
    path,
  };
};
