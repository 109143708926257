import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";

import PostListItem from "./PostListItem";
import { Post } from "../types";

const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
`;

const Title = styled.div`
  color: ${(props) => props.theme.color.secondary.minus};
  display: flex;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoint.down("sm")} {
    justify-content: center;
  }
`;

interface Props {
  posts: Post[];
  title?: string;
}

interface State {
  activePost?: PostListItem;
}

export default class PostList extends Component<Props, State> {
  state: State = {
    activePost: undefined,
  };

  render() {
    let { posts, title } = this.props;
    return (
      <Container>
        {title && <Title>{title}</Title>}
        <Grid container>
          {posts &&
            posts
              .filter((post) => !post.preview)
              .sort((a, b) => {
                return b.posted_at - a.posted_at;
              })
              .map((post) => (
                <Grid key={post.id} item xs={12}>
                  <PostListItem
                    post={post}
                    onActive={this.handlePostActivation}
                    onInactive={this.handlePostDeactivation}
                  />
                </Grid>
              ))}
        </Grid>
      </Container>
    );
  }

  handlePostActivation = (post: PostListItem) => {
    if (post === this.state.activePost) {
      return;
    }

    const prevPost = this.state.activePost;

    this.setState({ activePost: post });

    // covers cases when posts don't deactivate by themselves
    prevPost && prevPost.deactivate();
  };

  handlePostDeactivation = (post: PostListItem) => {
    if (post !== this.state.activePost) {
      return;
    }

    this.setState({ activePost: undefined });
  };
}
