import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  color: #999;
  text-align: center;

  &:hover {
    color: #6b6b6b;
  }
`;

interface Props {
  index: number;
  pageCount: number;
  buildPath: (index: number) => string;
}

const Paginator: React.FC<Props> = ({ index, pageCount, buildPath }) => {
  const prev = index === 1 ? null : buildPath(index - 1);
  const next = index === pageCount ? null : buildPath(index + 1);

  return (
    <Container>
      {prev ? <StyledLink to={prev}>{"< NEWER POSTS"}</StyledLink> : null}
      {next ? <StyledLink to={next}>{"OLDER POSTS >"}</StyledLink> : null}
    </Container>
  );
};

export default Paginator;
