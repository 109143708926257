import React, { Component } from "react";
import styled from "styled-components";
import { upperCase } from "upper-case";
import { lowerCase } from "lower-case";
import { Link } from "gatsby";
import showdown from "showdown";
import dateFormat from "dateformat";
import { gray } from "@tleef/colors";

import Category from "./CategoryLink";
import { Post } from "../types";

const converter = new showdown.Converter();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.color.separator};
  margin-bottom: 60px;
  width: 100%;
`;

const CategoryContainer = styled.div`
  margin-bottom: 15px;
`;

const TitleContainer = styled.div`
  margin-bottom: 25px;
`;

const Title = styled(Link)`
  display: inline-flex;
  color: ${(props) => props.theme.color.secondary.main};
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 1.1;
`;

const Description = styled.div`
  display: flex;
  margin-bottom: 15px;
  color: ${(props) => props.theme.color.secondary.minus};

  & > p:last-child {
    margin-bottom: 0;
  }
`;

const ReadMoreContainer = styled.div`
  margin-bottom: 25px;
`;

const ReadMore = styled(Link)`
  position: relative;
  display: inline-flex;
  color: ${(props) => props.theme.color.primary.minus};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 4px 0;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    background: ${(props) => props.theme.color.primary.minus};
    opacity: 0;
    transform: translateY(10px);
    width: 100%;
    height: 4px;
    transition: ${(props) =>
      props.theme.transition.create(["opacity", "transform"])};
  }

  &:hover:after {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MetaDataItem = styled(Link)`
  display: flex;
  color: ${(props) => props.theme.color.secondary.minus};
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    color: ${(props) => props.theme.color.secondary.main};
  }
`;

const MetaData = styled.div`
  display: flex;

  & > ${MetaDataItem}:not(:last-child) {
    margin-right: 10px;
  }

  & > ${MetaDataItem}:not(:last-child):after {
    content: "-";
    color: ${gray[600]};
    margin-left: 10px;
  }
`;

interface Props {
  post: Post;
  onActive?: (item: PostListItem) => void;
  onInactive?: (item: PostListItem) => void;
}

interface State {
  active: boolean;
}

export default class PostListItem extends Component<Props, State> {
  state: State = {
    active: false,
  };

  render() {
    let { post } = this.props;

    const link = post.path;

    return (
      <Container
        onMouseEnter={this.activate}
        onMouseLeave={this.deactivate}
        onTouchStart={this.activate}
        onTouchEnd={this.deactivate}
      >
        <CategoryContainer>
          <Category to={`/blog/category/${lowerCase(post.category)}`}>
            {upperCase(post.category)}
          </Category>
        </CategoryContainer>
        <TitleContainer>
          <Title to={link}>{post.title}</Title>
        </TitleContainer>
        <Description
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(post.description || post.excerpt),
          }}
        />
        <ReadMoreContainer>
          <ReadMore to={link}>{"READ MORE"}</ReadMore>
        </ReadMoreContainer>
        <MetaData>
          <MetaDataItem to={"/about"}>{post.author}</MetaDataItem>
          <MetaDataItem to={link}>
            {dateFormat(new Date(post.posted_at * 1000), "UTC:mmmm dS, yyyy")}
          </MetaDataItem>
        </MetaData>
      </Container>
    );
  }

  activate = () => {
    if (this.state.active) {
      return;
    }

    this.setState({ active: true });

    if (this.props.onActive) {
      this.props.onActive(this);
    }
  };

  deactivate = () => {
    if (!this.state.active) {
      return;
    }

    this.setState({ active: false });

    if (this.props.onInactive) {
      this.props.onInactive(this);
    }
  };
}
